import { Component, NgZone, OnDestroy, OnInit, RendererFactory2 } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import Auth0Cordova from '@auth0/cordova';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
// import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx'; // mobile
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AlertController, Config, LoadingController, MenuController, Platform, ToastController } from '@ionic/angular';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import version from '../../package.json';
import { environment } from '../environments/environment';
import { AnalyticsService } from './core/services/analytics.service';
import { AuthService } from './core/services/auth.service';
import { PushService } from './providers/push.service';
import { RemoteConfigService } from './core/services/remote-config.service';
import { SubscriptionService } from './providers/subscription.service';
import { Device } from './providers/traccar-client/index';
import { TrackerService } from './providers/tracker.service';
import { DeviceService } from './core/services/device.service';
import { UserService } from './core/services/user.service';
import { PassportService } from './core/services/passport.service';
import { UrlOpenerService } from './core/services/url-opener.service';
// Comment-in for Bafang demo
// import { BikeControllerCommunicationService } from './providers/bikeController/bikeControllerCommunication.service';
// and put the following into constructor
// public bikeControllerCommunicationService: BikeControllerCommunicationService,

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  INVITATION_LINK_REGEX = /#invitation\/redeem\/(PGV-\d*-[0-9a-fA-F]+)/;

  web: boolean = environment.webPlatform;
  deployment = environment.production ? 'Production' : 'Development';
  enableHelp = environment.enableHelp;
  enableNews = environment.enableNews;
  customHelpTitle =
    environment.customHelpTitle && environment.customHelpTitle !== '' ? environment.customHelpTitle : undefined;

  selectedDeviceId = this.trackers.getSelected().pipe(
    map((tracker) => tracker.id),
    shareReplay(1),
  );

  selectedAttributes = this.trackers.getSelected().pipe(
    switchMap((tracker) => tracker.getDevice()),
    map((device) => device.attributes),
    shareReplay(1),
  );

  devices: Map<number, BehaviorSubject<Device>> = new Map();
  deviceBatteryStates = {};
  version: string;
  profile: any;
  searchControl: FormControl = new FormControl();
  showSearch = false;
  searchValue = '';
  hideMenu = false;
  showPassportAlert = true;
  isNewsInitialised = false;
  isNewsOpen = false;

  offlineToast;
  offlineToastShown = false;

  // injected values
  injectedPushToken;
  injectedUsername;
  injectedPassword;
  private finalize$: Subject<void> = new Subject();

  constructor(
    public alertCtrl: AlertController,
    public analytics: AnalyticsService,
    public appVersion: AppVersion,
    public auth: AuthService,
    public config: Config,
    private deviceService: DeviceService,
    public loadingController: LoadingController,
    public menu: MenuController,
    public network: Network,
    private passportService: PassportService,
    public platform: Platform,
    public push: PushService,
    public remoteConfigService: RemoteConfigService,
    public rendererFactory: RendererFactory2,
    public route: ActivatedRoute,
    public router: Router,
    public statusBar: StatusBar,
    public storage: Storage,
    public subService: SubscriptionService,
    public toastCtrl: ToastController,
    public trackers: TrackerService,
    public translate: TranslateService,
    private userService: UserService,
    public zone: NgZone,
    private urlOpener: UrlOpenerService,
  ) {
    this.initializeApp();
  }

  onResume() {
    // (re)initialise everytime on resume so we do not trigger the popup everytime and properly handle the link
    // branchio requires to be initialised everytime, see example: https://github.com/besport/branch-cordova-sdk#initialize-branch
    this.initializeBranchio();
  }

  // NOTE Important: be careful with what you put into ngOnInit and Ctor, release build code optimisations might break the behaviour
  // e.b. calling "this.appVersion.getVersionNumber()" in ngOnInit fails with "plugin_not_installed" error

  async ngOnInit() {
    this.auth.user$
      .pipe(
        tap((profile) => (this.profile = profile)),
        filter((profile) => profile !== null),
        take(1),
      )
      .subscribe((profile) => {
        if (!profile.email_verified) {
          this.router.navigate(['/verification']);
        }

        if (this.enableNews) {
          (window as any).beamer_config = {
            product_id: environment.beamerId,
            selector: 'button-open-news',
            user_id: environment.production ? profile.user_id : 'staging',
            user_firstname: profile.given_name,
            user_lastname: profile.family_name,
            right: 26,
            top: 14,
            language: this.translate.currentLang.toUpperCase(),
            // filter: 'admin',
            delay: 2000,
            callback: this.onNewsCallback.bind(this),
            onopen: this.onNewsOpen.bind(this),
            onclose: this.onNewsClose.bind(this),
            onclick: (url) => {
              // open in external browser
              this.urlOpener.openUrl(url);
              // false for stopping beamer from opening in current webview
              return false;
            },
          };
          this.addJsToElement('https://app.getbeamer.com/js/beamer-embed.js');
        }

        this.push.init(this.profile.embeddedPushToken);
      });

    this.searchControl.valueChanges.subscribe((search) => {
      this.searchValue = search?.toLowerCase() || '';
    });

    this.trackers.getTrackers().subscribe((tracker) => {
      this.devices.set(tracker.id, tracker.getDevice());
      tracker
        .getLastPosition()
        .pipe(filter((position) => !!position.attributes?.batteryLevel))
        .subscribe(
          (position) => (this.deviceBatteryStates[tracker.id] = this.trackers.generateBatteryIconPath(position)),
        );
      // once list is long enough (initial load) show search field
      this.showSearch = this.devices.size > 12;
      // Tracker deleted
      tracker.getDevice().subscribe({ complete: () => this.devices.delete(tracker.id) });
    });

    this.storage.get('showPassportAlert').then((show) => {
      this.showPassportAlert = typeof show !== 'undefined' && show !== null ? show : true;
    });

    // No dialogs / subscription for B2B users or devices
    this.userService.user$
      .pipe(
        take(1),
        filter((user) => user.attributes.category !== 'B2B'),
        switchMap(() => this.selectedAttributes),
        filter((attributes) => !attributes.B2B && window.location.pathname === '/map'),
      )
      .subscribe(async (attributes) => {
        await this.subService.nonB2bSubscriptionCases(attributes);

        // passport generation dialog
        if (!attributes.passport && (attributes.trialEnd || attributes.subscription) && this.showPassportAlert) {
          this.showPassportAlert = false;
          const alert = await this.alertCtrl.create({
            header: this.translate.instant('PASSPORT_DIALOG_TITLE'),
            message: this.translate.instant('PASSPORT_DIALOG_TEXT'),
            inputs: [
              {
                name: 'stopCheckbox',
                type: 'checkbox',
                label: this.translate.instant('PASSPORT_DIALOG_STOP_REMINDER'),
                value: 'stop',
              },
            ],
            buttons: [
              {
                text: this.translate.instant('LATER'),
                cssClass: 'secondary',
                handler: (value) => {
                  if (value[0] === 'stop') {
                    this.initEmptyPassport();
                  }
                },
              },
              {
                text: this.translate.instant('PROCEED'),
                handler: (value) => {
                  if (value[0] === 'stop') {
                    this.initEmptyPassport();
                  } else {
                    this.router.navigateByUrl('/passport');
                  }
                },
              },
            ],
          });
          await alert.present();
        }
      });

    // fix the user attributes if missing the cbCustomerId but the selected tracker has a valid subscription and log it, see https://powunity.atlassian.net/browse/PB-1605
    combineLatest([this.selectedAttributes, this.userService.user$.pipe(take(1))])
      .pipe(
        filter(
          ([deviceAttributes, user]) =>
            user.attributes.category !== 'B2B' &&
            !user.attributes.cbCustomerId &&
            !!deviceAttributes.subscription?.customer_id,
        ),
        map(([deviceAttributes, user]) => {
          console.log('User has no cbCustomerId updating now', deviceAttributes.subscription.customer_id);
          user.attributes.cbCustomerId = deviceAttributes.subscription.customer_id;
          return user;
        }),
        switchMap((user) => this.userService.updateUser(user)),
        tap(() => this.analytics.captureMessage('Updated missing cbCustomerId from device subscription')),
      )
      .subscribe();

    this.trackers
      .getSelected()
      .pipe(
        switchMap((tracker) => tracker.getDevice()),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        withLatestFrom(this.passportService.invalidMandatoryFields$),
        map(([_, invalidFields]) => invalidFields),
        takeUntil(this.finalize$),
      )
      .subscribe((invalidFields: string[]) => {
        if (invalidFields.length <= 0) return;
        this.alertCtrl
          .create({
            header: this.translate.instant('SHARED.INVALID_VALUE', { value: this.translate.instant('PASSPORT') }),
            message: this.translate.instant('SHARED.MANDATORY_PASSPORT_INFORMATION_MISSING'),
            buttons: [
              {
                text: this.translate.instant('CANCEL'),
                role: 'cancel',
              },
              {
                text: this.translate.instant('PASSPORT'),
                role: 'confirm',
                handler: () => this.router.navigateByUrl('passport'),
              },
            ],
          })
          .then((alert) => alert.present());
      });
  }

  public ngOnDestroy(): void {
    this.finalize$.next();
    this.finalize$.complete();
  }

  async initializeApp() {
    await this.platform.ready();
    await this.storage.create();

    // listen to resume events
    document.addEventListener('resume', this.onResume.bind(this), false);

    // see https://github.com/apache/cordova-ios/blob/master/guides/Cordova%20Custom%20URL%20Scheme%20Handling.md
    (window as any).handleOpenURL = (url: string) => {
      console.log('handleOpenURL', url);
      Auth0Cordova.onRedirectUri(url);
      // this context is called outside of angular zone, so we need to get back into the zone..
      setTimeout(
        () =>
          this.zone.run(() => {
            // simple custom deeplink handling, only supports last segment
            // e.g. "com.powunity.app://map" or "https://app.powunity.com/map" -> redirect to /map page
            const page = url.substring(url.lastIndexOf('/'));
            this.router.navigateByUrl(page);
          }),
        0,
      );
    };

    this.startConnectivityMonitor();

    // storage is ready, now check for injected values
    if (environment.webPlatform) {
      const injectedPassword = this.platform.getQueryParam('password');
      const injectedPushToken = this.platform.getQueryParam('pushToken');
      const injectedUsername = this.platform.getQueryParam('username');
      const injectedDeviceId = this.platform.getQueryParam('deviceId');

      // we always want all three values in an embedded version
      if (injectedPassword && injectedUsername) {
        await this.auth.initEmbeddedSession(injectedUsername, injectedPassword, injectedPushToken).toPromise();
      }

      // select tracker if required after trackers are loaded
      if (injectedDeviceId) {
        this.selectedDeviceId.pipe(take(1)).subscribe(async (id) => {
          const deviceId = Number(injectedDeviceId);

          if (isNaN(deviceId) || !this.trackers.hasTracker(deviceId)) {
            await this.showErrorAlert('ERROR', 'TRACKER_SELECT_ERROR');
            return;
          }

          if (id !== deviceId) {
            this.trackers.select(deviceId);
          }
        });
      }
    }

    await this.initTranslate();

    this.auth.login().subscribe({
      error: (error) => this.onLoginFailed(error),
    });

    if (!environment.webPlatform) {
      this.initNative();
    }

    this.appVersion.getVersionNumber().then((version) => {
      this.version = `V${version}`;
    });

    // Track screen on Firebase analytics and hide sidebar for certain screens
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.hideMenu = /^\/(creation|configure|detail|logout|sharing|subscription|verification)(\/|$)/.test(event.url);
      this.analytics.updateScreenName(event.url);
    });

    this.initializeBranchio();
  }

  initEmptyPassport() {
    this.storage.set('showPassportAlert', false);
    this.trackers
      .getSelected()
      .pipe(switchMap((tracker) => tracker.setAttribute('passport', {})))
      .subscribe();
  }

  onNewsCallback() {
    this.isNewsInitialised = true;
  }

  onNewsOpen() {
    this.isNewsOpen = true;
  }

  onNewsClose() {
    this.isNewsOpen = false;
  }

  initNative() {
    this.statusBar.styleLightContent();
    this.statusBar.overlaysWebView(false);

    // get colour from :root via variable, note: trim() is required as we somehow get a leading whitespace...
    const colour = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary-shade').trim();
    this.statusBar.backgroundColorByHexString(colour);

    if (this.platform.is('android')) {
      // Workaround for app not closing the app with back button - not sure why that is
      this.platform.backButton.subscribe(() => {
        if (this.isNewsOpen) {
          const beamer = (window as any).Beamer;
          if (beamer) {
            beamer.hide();
          }
        } else if (window.location.pathname === '/map') {
          (navigator as any).app.exitApp();
        }
      });
    }

    // setTimeout(() => this.splashScreen.hide(), environment.splashScreenDelayMs);
  }

  async initTranslate() {
    // Set the default language for translation strings, and the current language.
    this.translate.setDefaultLang('en');
    const browserLang = this.translate.getBrowserLang();
    // NOTE: also add date translations to date-util.ts when adding new language
    const lang = /^(cs|da|de|en|es|fr|hr|nl|pl|sk|sv)/gi.test(browserLang) ? browserLang : 'en';
    await this.translate.use(lang).pipe(first()).toPromise();
    moment.locale(lang);
    moment.updateLocale(lang, {
      calendar: {
        sameElse: 'LLL',
      },
    });
    console.log('lang', lang);
  }

  initializeBranchio() {
    if (!this.platform.is('desktop')) {
      const { Branch } = window as any;

      Branch?.initSession()
        .then((data) => {
          // handle deeplinks in case we got one
          if (
            Branch?.sessionInitialized &&
            data['+clicked_branch_link'] &&
            data['~referring_link']?.includes('invitation/redeem/')
          ) {
            // handling of invitations
            const invitation = this.INVITATION_LINK_REGEX.exec(data['~referring_link']);
            const token = invitation[1]; // first capture group
            this.redeemInvitationAlert(token);
          }
        })
        .catch((err) => {
          console.log('Error while initialising branchio', err);
          this.analytics.captureException(err);
        });
    } else {
      // TODO remove all debug logs in this block later

      // desktop flow reverse engineered from https://help.branch.io/developers-hub/docs/web-full-reference#quick-install
      const win = window as any;
      // check if we already loaded
      const scriptElem = document.getElementById('branch-io');

      // add script tag for desktop, but only once
      if (!scriptElem) {
        console.log('Adding Branchio script now');
        this.addJsToElement('https://cdn.branch.io/branch-latest.min.js', 'branch-io');
        console.log('Branchio script added');
      }

      /* eslint-disable no-underscore-dangle */
      // add initialisation object for branch, but only if not existing AND script tag not yet added
      if (!win.branch || (!win.branch._q && !scriptElem)) {
        console.log('Adding Branchio object now');

        const functionNames =
          'addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking getBrowserFingerprintId crossPlatformIds lastAttributedTouchData'.split(
            ' ',
          );
        const branchObject = {
          _q: [],
          _v: 1,
        };

        for (let i = 0; i < functionNames.length; i += 1) {
          branchObject[functionNames[i]] = (...args) => {
            branchObject._q.push([functionNames[i], args]);
          };
        }

        win.branch = branchObject;

        console.log('Branchio object added');
      }

      if (environment.branchioToken && environment.branchioToken !== '') {
        win.branch.init(environment.branchioToken, (err, data) => {
          if (err) {
            console.error('Error while initialising branchio', err);
            this.analytics.captureException(err);
            return;
          }

          console.log('Branchio initialised');

          if (data.data && data.data !== '' && data.data_parsed?.['+clicked_branch_link']) {
            let referringLink;

            if (data.data_parsed?.['~referring_link']?.includes('invitation/redeem/')) {
              // try referring link from branchio
              referringLink = data.data_parsed['~referring_link'];
            } else if (document.location.href.includes('invitation/redeem/')) {
              // try fallback to current document location
              referringLink = document.location.href;
            }

            if (!referringLink) {
              console.log('No referring link found', document.location.href);
              return;
            }

            console.log('Invitation link found', referringLink);
            // handling of invitations
            const invitation = this.INVITATION_LINK_REGEX.exec(referringLink);
            const token = invitation[1]; // first capture group
            this.redeemInvitationAlert(token);
          }
        });
      }
      /* eslint-enable no-underscore-dangle */
    }
  }

  selectTracker(deviceSource: Observable<Device>) {
    this.menu.close();
    deviceSource.pipe(take(1)).subscribe((device) => this.trackers.select(device.id));
  }

  login() {
    this.auth.login().subscribe({
      error: (error) => this.onLoginFailed(error),
    });
  }

  async showErrorAlert(header: string = 'ERROR', message: string = 'UNKNOWN_ERROR') {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant(header),
      message: this.translate.instant(message, { emailSupport: environment.emailSupport }),
      buttons: ['OK'],
    });
    await alert.present();
  }

  async redeemInvitationAlert(token: string) {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('TRACKERSHARE_REDEEM'),
      message: this.translate.instant('TRACKERSHARE_REDEEM_MESSAGE'),
      buttons: [
        {
          text: this.translate.instant('CANCEL'),
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'OK',
          handler: async () => {
            const loading = await this.loadingController.create();
            await loading.present();

            this.deviceService
              .redeemDeviceInvitation(token)
              .pipe(switchMap((result: any) => this.deviceService.addAssignedDevice(result.device)))
              .subscribe(
                async (device) => {
                  // select the new tracker
                  this.trackers.select(device.id);

                  const successToast = await this.toastCtrl.create({
                    message: this.translate.instant('SUCCESSFULLY_ADDED_TRACKER'),
                    duration: 5000,
                  });
                  await loading.dismiss();
                  await successToast.present();
                },
                async (err) => {
                  console.log('Error while redeeming invitation', err);
                  this.analytics.captureException(err);
                  await loading.dismiss();
                  await this.showErrorAlert(undefined, 'TRACKERSHARE_REDEEM_ERROR');
                },
              );
          },
        },
      ],
    });
    await alert.present();
  }

  openHelp() {
    this.menu.close();
    this.urlOpener.openHelp();
  }

  async logout() {
    await this.auth.logout().toPromise();
    this.router.navigate(['logout'], { replaceUrl: true}); // navigate without history / back button support
  }

  async onLoginFailed(err) {
    console.warn('Login failed', err);

    const errorMessage = err.message || err.errorDescription;

    // special handling for migrating users to non-social logins
    // TODO remove later?
    const customAuth0ErrorEnRegex = RegExp('#EN#([^#]*)#');
    const customAuth0ErrorDeRegex = RegExp('#DE#([^#]*)#');

    let msg = this.translate.instant('LOGIN_FAILED_UNKNOWN_MSG', { emailSupport: environment.emailSupport });
    if (errorMessage && customAuth0ErrorEnRegex.test(errorMessage)) {
      // special handling for migrating users to non-social logins
      // TODO remove later?

      // show german in case it is available and set as lang
      if (this.translate.getBrowserLang() === 'de' && customAuth0ErrorDeRegex.test(errorMessage)) {
        msg = `${
          errorMessage.match(customAuth0ErrorDeRegex)[1]
        }<br/><br/>Sollte das Problem weiter bestehen kontaktiere unseren Support: ${environment.emailSupport}`;
      } else {
        // otherwise show english
        msg = `${
          errorMessage.match(customAuth0ErrorEnRegex)[1]
        }<br/><br/>If the problem persists please contact our support: ${environment.emailSupport}`;
      }
    } else if (errorMessage !== 'user canceled') {
      // TODO check typo
      this.analytics.captureException(err);
    }
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('LOGIN_FAILED_TITLE'),
      message: msg,
      buttons: [
        {
          text: 'OK',
        },
      ],
    });
    //In all cases we want user to go to login page since the auth process failed/canceled
    alert.onDidDismiss().finally(()=>{
      this.login()
    });
    await alert.present();
  }

  startConnectivityMonitor() {
    this.network.onDisconnect().subscribe(async () => {
      console.log('Disconnected');
      if (!this.offlineToastShown) {
        this.offlineToast = await this.toastCtrl.create({
          message: this.translate.instant('NETWORK_OFFLINE_HINT'),
          duration: 6000,
          buttons: [
            {
              text: 'X',
              role: 'cancel',
            },
          ],
        });
        this.offlineToast.onDidDismiss().then(() => (this.offlineToastShown = false));
        this.offlineToast.present();
        this.offlineToastShown = true;
      }
    });
    this.network.onConnect().subscribe(() => {
      console.log('Connected');
      if (this.offlineToast && this.offlineToastShown) {
        this.offlineToast.dismiss();
      }
    });
  }

  // Same code in subscription service -> move to helper service
  // NOTE: cache renderer if called multiple times (https://stackoverflow.com/a/47925259)
  addJsToElement(src: string, domId?: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.src = src;
    if (domId) {
      script.id = domId;
    }
    this.rendererFactory.createRenderer(null, null).appendChild(document.body, script);
    return script;
  }

  // TODO move this to own service?
  /**
   * APPLE WATCH APP HANDLING
   */

  watchPluginSendMessage(message: string) {
    const watchappPlugin = (window as any).Biketrax;

    if (!watchappPlugin) {
      console.log('App plugin for watch app not found, sending message not possible');
      return;
    }

    try {
      watchappPlugin.sendMessage(
        message,
        () => console.log('Message sent to watch app plugin', message),
        (error: any) => console.error('Received error from watch app plugin while sending message', message, error),
      );
    } catch (err) {
      console.log('Error while sending message to watch app plugin', message, err);
    }
  }

  watchPluginReceivedMessage(message: string) {
    console.log('Received message from watch app plugin', message);

    switch (message) {
      case 'getAuthentication':
        this.auth.user$
          .pipe(
            tap((profile) => (this.profile = profile)),
            filter((profile) => profile !== null),
            take(1),
          )
          .subscribe((profile) => {
            const { email, traccarPassword } = profile;
            const sendMessage = `authentication::${email}::${traccarPassword}`;
            this.watchPluginSendMessage(sendMessage);
          });
        break;

      default:
        console.log(`Unknown message received from watch app plugin: ${message}`);
        break;
    }
  }

  watchPluginReceivedError(error: any) {
    console.error('Received error from watch app plugin', error);
  }

  watchPluginSubscribeToMessages() {
    console.log('Subscribing to Apple Watch plugin');

    const watchappPlugin = (window as any).Biketrax;

    if (!watchappPlugin) {
      console.log('Watch app plugin not found, not subscribing');
      return;
    }

    try {
      watchappPlugin.subscribeToMessages(this.watchPluginReceivedMessage.bind(this), this.watchPluginReceivedError.bind(this));
      console.log('Successfully subscribed to Apple Watch plugin');
    } catch (err: any) {
      console.error('Error while trying to subscribe to apple watch plugin messages');
    }
  }
}
