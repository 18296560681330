// Angular
import { Injectable, OnDestroy } from '@angular/core';

// Reactive X
import { merge, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

// He
import * as he from 'he';

// Internal dependencies
import { retryUnless } from '../utils/operators';

// eslint-disable-next-line @typescript-eslint/no-redeclare
import { Command, Device, Event, Position } from '../../../app/providers/traccar-client';
import { DeviceGuarding } from '../models/device/device-guarding';
import { Geofence } from '../models/geofence/geofence';
import { GeofenceDTO } from '../models/geofence/geofence-dto';
import { Trip } from '../models/trip';

import { AdminBackendClient } from './admin-backend/admin-backend-client';
import { TraccarClient } from './traccar/traccar-client';
import { TraccarWebSocket } from './traccar/traccar-web-socket';

@Injectable({
  providedIn: 'root',
})
export class DeviceService implements OnDestroy {
  /* ATTRIBUTES */

  private imminentDestruction$: Subject<void> = new Subject();

  private deviceUpdatesSource$: Subject<Device> = new Subject();
  public readonly deviceUpdates$ = merge(this.deviceUpdatesSource$, this.traccarWebSocket.deviceUpdates$).pipe(
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    takeUntil(this.imminentDestruction$),
  );

  private deviceEventUpdatesSource$: Subject<Event> = new Subject();
  public readonly deviceEventUpdates$ = merge(this.deviceEventUpdatesSource$, this.traccarWebSocket.eventUpdates$).pipe(
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    takeUntil(this.imminentDestruction$),
  );

  private devicePositionUpdatesSource$: Subject<Position> = new Subject();
  public readonly devicePositionUpdates$ = merge(
    this.devicePositionUpdatesSource$,
    this.traccarWebSocket.positionUpdates$,
  ).pipe(
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    takeUntil(this.imminentDestruction$),
  );

  private deviceAccessRevocationUpdatesSource$: Subject<number> = new Subject();
  public readonly deviceAccessRevocationUpdates$ = this.deviceAccessRevocationUpdatesSource$.pipe(
    distinctUntilChanged(),
  );

  /* LIFECYCLE */

  public constructor(
    private adminBackend: AdminBackendClient,
    private traccar: TraccarClient,
    private traccarWebSocket: TraccarWebSocket,
  ) {}

  public ngOnDestroy(): void {
    this.imminentDestruction$.next();
    this.imminentDestruction$.complete();

    this.deviceUpdatesSource$.complete();
    this.deviceEventUpdatesSource$.complete();
    this.devicePositionUpdatesSource$.complete();

    this.deviceAccessRevocationUpdatesSource$.complete();
  }

  /* METHODS */

  public createDevice(device: Device): Observable<Device> {
    return this.traccar.post<Device>('devices', device).pipe(
      tap(() => {
        console.log('Created device', device);
        // For consistency this should also be called, however this causes bugs right now.
        // this.deviceUpdateSource$.next(device);
      }),
      retryUnless(3, 1000, (error) => error?.status === 400),
    );
  }

  public hardwareExchange(
    deviceId: number,
    newUniqueId: string,
    options: { isOldHardwareRetired?: boolean } = { isOldHardwareRetired: false },
  ): Observable<any> {
    return this.adminBackend.post(`v2/devices/${deviceId}/hardware-exchange`, {
      newUniqueId: newUniqueId,
      isOldHardwareRetired: options.isOldHardwareRetired ?? false,
    });
  }

  public replaceDevice(uniqueId: string, newUniqueId: string) {
    return this.adminBackend.post(`devices/${uniqueId}/exchange/${newUniqueId}`, {});
  }

  public createDeviceShare(uniqueId: string, recipientEmail: string, linkDomain: string): Observable<any> {
    return this.adminBackend
      .post(`devices/${uniqueId}/guestshares`, { recipientEmail, linkDomain })
      .pipe(tap(() => console.log('Created device share for device with unique ID', uniqueId, 'to', recipientEmail)));
  }

  public updateDevice(device: Device): Observable<Device> {
    return this.traccar.put<Device>(`devices/${encodeURIComponent(device.id)}`, device).pipe(
      tap(
        () => {
          console.log('Updated device', device);
          this.deviceUpdatesSource$.next(device);
        },
        (error) => this.onErrorForExistingDevice(device.id, error),
      ),
      retryUnless(3, 1000, (error) => error?.status === 400),
    );
  }

  public updateDeviceGuarding(deviceId: number, uniqueId: string, newDeviceGuarding: DeviceGuarding): Observable<any> {
    return this.adminBackend.post(`devices/${uniqueId}/${newDeviceGuarding}`, {}).pipe(
      tap(
        () => {
          if (newDeviceGuarding === DeviceGuarding.ENABLED) {
            console.log('Enabled device guarding');
          } else if (newDeviceGuarding === DeviceGuarding.DISABLED) {
            console.log('Disabled device guarding');
          }
        },
        (error) => this.onErrorForExistingDevice(deviceId, error),
      ),
      retryUnless(3, 1000, (error) => error?.status === 400),
    );
  }

  public updateDeviceTotalDistance(deviceId: number, totalDistance: number): Observable<Device> {
    return this.traccar
      .put<Device>(`devices/${encodeURIComponent(deviceId)}/accumulators`, { deviceId, totalDistance })
      .pipe(
        catchError((error: any) => {
          // Workaround for https://powunity.atlassian.net/browse/CB-306
          if (error.error?.startsWith('Manager access required')) {
            return this.fetchDeviceById(deviceId);
          }

          return throwError(error);
        }),
        tap(
          () => console.log('Updated total distance to', totalDistance, 'for device with ID', deviceId),
          (error) => this.onErrorForExistingDevice(deviceId, error),
        ),
        retryUnless(3, 1000, (error) => error?.status === 400),
      );
  }

  public deleteDevice(uniqueId: string): Observable<void> {
    return this.adminBackend
      .delete<void>(`devices/${uniqueId}`)
      .pipe(tap(() => console.log('Deleted device with unique ID', uniqueId)));
  }

  public deleteDevicePositions(deviceId: number, fromDate: Date, toDate: Date): Observable<any> {
    const requestParams = {
      deviceId,
      from: fromDate.toISOString(),
      to: toDate.toISOString(),
    };

    return this.traccar.delete('positions', { params: requestParams }).pipe(
      tap(
        () =>
          console.log(
            'Deleted positions for device with ID',
            deviceId,
            'from',
            fromDate.toLocaleString(),
            'to',
            toDate.toLocaleString(),
          ),
        (error) => this.onErrorForExistingDevice(deviceId, error),
      ),
      retryUnless(3, 1000, (error) => error?.status === 400),
    );
  }

  public deleteDeviceShare(uniqueId: string, deviceShareId: string): Observable<any> {
    return this.adminBackend
      .delete(`devices/${uniqueId}/guestshares/${deviceShareId}`)
      .pipe(
        tap(() => console.log('Deleted device share with ID', deviceShareId, 'for device with unique ID', uniqueId)),
      );
  }

  /**
   * TODO: Needs further refactoring.
   */
  public addAssignedDevice(device: Device): Observable<Device> {
    // Add device to list in any case
    this.deviceUpdatesSource$.next({ ...device, name: he.decode(device.name) });

    // Try to get the position of this device if available
    return this.fetchDevicePositionById(device.id, device.positionId).pipe(
      map((position) => {
        this.devicePositionUpdatesSource$.next(position);
        return device;
      }),
      // In case of error getting the position, return the device anyway
      catchError(() => of(device)),
    );
  }

  public startBikeRecovery(uniqueId: string, pdfContent: string): Observable<any> {
    return this.adminBackend
      .post(`devices/${uniqueId}/bike-recovery`, { pdfContent })
      .pipe(
        tap(() =>
          console.log('Requested bike recovery for device with unique ID', uniqueId),
        ),
      );
  }

  public informDeviceOwner(uniqueId: string): Observable<any> {
    return this.adminBackend
      .post(`devices/${uniqueId}/assign_to_other_user`, {
        responseType: 'text',
      })
      .pipe(tap(() => console.log('Informed owner of device with unique ID', uniqueId)));
  }

  public redeemDeviceInvitation(invitationToken: string): Observable<any> {
    return this.adminBackend
      .post('invitations/redeem', { token: invitationToken })
      .pipe(tap(() => console.log('Redeemed device invitation token', invitationToken)));
  }

  /**
   * NOTE: make sure the device already provided a position before calling this.
   */
  public sendCommand(
    deviceId: number,
    uniqueId: string,
    commandId: string,
    commandData?: any,
    commandType: string = 'S71,085902',
  ): Observable<string> {
    const command: string = `*HQ,${uniqueId},${commandType},${commandId}${commandData ? ',' + commandData : ''}#`;
    const requestBody = {
      deviceId,
      type: 'custom',
      attributes: {
        data: command,
      },
    };

    return this.traccar.post<Command>('commands/send', requestBody).pipe(
      tap(
        () => console.log('Sent command', command, 'to device with ID', deviceId),
        (error) => this.onErrorForExistingDevice(deviceId, error),
      ),
      retryUnless(3, 1000, (error) => error?.status === 400),
      switchMap(() => this.devicePositionUpdates$),
      filter((position) => position.deviceId === deviceId && position.attributes?.command === commandId),
      map((position) => position.attributes.result),
      take(1),
    );
  }

  /**
   * NOTE: The returned devices simply serve convenience (and compatibilty with existing code).
   * Its usage might indicate imperative programming. Whenever possible use the provided 'deviceUpdates$' stream instead.
   */
  public refreshDevices(): Observable<Device[]> {
    return this.fetchDevices().pipe(
      tap((devices: Device[]) => {
        for (const device of devices) {
          this.deviceUpdatesSource$.next({ ...device, name: he.decode(device.name) });
        }
      }),
    );
  }

  /**
   * NOTE: The returned events simply serve convenience (and compatibilty with existing code).
   * Its usage might indicate imperative programming. Whenever possible use the provided 'deviceEventUpdates$' stream instead.
   */
  public refreshDeviceEvents(deviceId: number, fromDate: Date, toDate: Date): Observable<Event[]> {
    return this.fetchDeviceEvents(deviceId, fromDate, toDate).pipe(
      tap((deviceEvents: Event[]) => {
        for (const deviceEvent of deviceEvents) {
          this.deviceEventUpdatesSource$.next(deviceEvent);
        }
      }),
    );
  }

  /**
   * NOTE: The returned positions simply serve convenience (and compatibilty with existing code).
   * Its usage might indicate imperative programming. Whenever possible use the provided 'devicePositionUpdates$' stream instead.
   */
  public refreshDevicePositions(deviceId: number, fromDate: Date, toDate: Date): Observable<Position[]> {
    return this.fetchDevicePositions(deviceId, fromDate, toDate).pipe(
      tap((devicePositions: Position[]) => {
        for (const devicePosition of devicePositions) {
          this.devicePositionUpdatesSource$.next(devicePosition);
        }
      }),
    );
  }

  /* EVENT HANDLERS */

  private onErrorForExistingDevice(deviceId: number, error: any): void {
    // Identify device access revocations
    if (error?.status === 400 && error?.error?.startsWith('Manager access required')) {
      this.deviceAccessRevocationUpdatesSource$.next(deviceId);
    }
  }

  /* ACCESSORS */

  private fetchDevices(): Observable<Device[]> {
    return this.traccar.get<Device[]>('devices').pipe(
      tap((devices) => console.log('Fetched devices', devices)),
      retryUnless(3, 1000, (error) => error?.status === 400),
    );
  }

  private fetchDeviceById(id: number): Observable<Device> {
    return this.traccar.get<Device>('devices', { params: { id } }).pipe(
      tap((device) => console.log('Fetched device by id', device)),
      retryUnless(3, 1000, (error) => error?.status === 400),
    );
  }

  /**
   * NOTE: This method simply serves convenience (and compatibilty with existing code).
   * Its usage might indicate imperative programming. Whenever possible use 'refreshDeviceEvents'
   * in combination with the provided 'deviceEventUpdates$' stream instead.
   */
  public fetchDeviceEvents(deviceId: number, fromDate: Date, toDate: Date): Observable<Event[]> {
    const requestParams = {
      deviceId,
      from: fromDate.toISOString(),
      to: toDate.toISOString(),
    };

    return this.traccar.get<Event[] | null>('reports/events', { params: requestParams }).pipe(
      map((events) => events ?? []),
      tap(
        (events) => console.log('Fetched events', events, 'for device with ID', deviceId),
        (error) => this.onErrorForExistingDevice(deviceId, error),
      ),
      retryUnless(3, 1000, (error) => error?.status === 400),
    );
  }

  /**
   * TODO: Move this into a more fitting service (since it is independent of a device)
   */
  public fetchPosition(positionId: number): Observable<Position | undefined> {
    const requestParams = {
      id: positionId,
    };

    return this.traccar.get<Position | undefined>('positions', { params: requestParams }).pipe(
      map((positions) => positions ?? []),
      map((positions) => positions[0] ?? undefined),
      retryUnless(3, 1000, (error) => error?.status === 400),
    );
  }

  /**
   * NOTE: This method simply serves convenience (and compatibilty with existing code).
   * Its usage might indicate imperative programming. Whenever possible use 'refreshDevicePositions'
   * in combination with the provided 'devicePositionUpdates$' stream instead.
   */
  public fetchDevicePositions(
    deviceId: number,
    fromDate: Date,
    toDate: Date,
    options?: { allowDuplicatePositions?: boolean },
  ): Observable<Position[]> {
    const requestParams = {
      deviceId,
      from: fromDate.toISOString(),
      to: toDate.toISOString(),
    };

    return this.traccar.get<Position[] | null>('positions', { params: requestParams }).pipe(
      map((positions) => positions ?? []),
      map((positions) => {
        if (options?.allowDuplicatePositions === true) return positions;
        return this.filterPositionsForDuplicates(positions);
      }),
      tap(
        (positions) => console.log('Fetched positions', positions, 'for device with ID', deviceId),
        (error) => this.onErrorForExistingDevice(deviceId, error),
      ),
      retryUnless(3, 1000, (error) => error?.status === 400),
    );
  }

  private fetchDevicePositionById(deviceId: number, positionId: number): Observable<Position | null> {
    const requestParams = {
      deviceId,
      id: positionId,
    };

    return this.traccar.get<Position[] | null>('positions', { params: requestParams }).pipe(
      map((positions) => (positions ? positions[0] : null)),
      tap(
        (position) => console.log('Fetched position', position, 'by ID', positionId, 'for device with ID', deviceId),
        (error) => this.onErrorForExistingDevice(deviceId, error),
      ),
      retryUnless(3, 1000, (error) => error?.status === 400),
    );
  }

  /**
   * NOTE: This method might also be replaced by a combination of
   * 'refreshDeviceTrips' and 'deviceTripUpdates$' in future refactorings.
   */
  public fetchDeviceTrips(deviceId: number, fromDate: Date, toDate: Date): Observable<Trip[]> {
    const requestParams = {
      deviceId,
      from: fromDate.toISOString(),
      to: toDate.toISOString(),
    };

    return this.traccar.get<Trip[] | null>('reports/trips', { params: requestParams }).pipe(
      map((trips) => trips ?? []),
      tap(
        (trips) => console.log('Fetched trips', trips, 'for device with ID', deviceId),
        (error) => this.onErrorForExistingDevice(deviceId, error),
      ),
      retryUnless(3, 1000, (error) => error?.status === 400),
    );
  }

  /**
   * NOTE: This method might also be replaced by a combination of
   * 'refreshDeviceGeofences' and 'deviceGeofenceUpdates$' in future refactorings.
   */
  public fetchDeviceGeofences(deviceId: number): Observable<Geofence[]> {
    const requestParams = {
      deviceId,
    };

    return this.traccar.get<GeofenceDTO[] | null>('geofences', { params: requestParams }).pipe(
      map((geofenceDTOs) => geofenceDTOs ?? []),
      map((geofenceDTOs) => geofenceDTOs.map((geoFenceDTO) => Geofence.fromDTO(geoFenceDTO))),
      tap(
        (geofences) => console.debug('Fetched device geofences', geofences),
        (error) => this.onErrorForExistingDevice(deviceId, error),
      ),
      retryUnless(3, 1000, (error) => error?.status === 400),
    );
  }

  public fetchDeviceShares(uniqueId: string): Observable<any[]> {
    return this.adminBackend
      .get<any[]>(`devices/${uniqueId}/guestshares`)
      .pipe(tap((shares) => console.log('Fetched device shares', shares)));
  }

  /* UTILITY */

  private filterPositionsForDuplicates(positions: Position[]): Position[] {
    return positions.filter((position: Position, index: number) => {
      if (index === 0) return true;

      const hasDifferentLatitude = position.latitude !== positions[index - 1].latitude;
      const hasDifferentLongitude = position.longitude !== positions[index - 1].longitude;

      return hasDifferentLatitude || hasDifferentLongitude;
    });
  }
}
